var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticClass:"fill-height mt-n3 mx-n4"},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":"","show-arrows":""}},[_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-stock-items'
                    : 'archive-project-stock-items',
                params: {
                    projectId: _vm.projectId,
                },
            }}},[_vm._v(" ESM STOCK ")]),_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-requested-items'
                    : 'archive-project-requested-items',
                params: {
                    projectId: _vm.projectId,
                },
            }}},[_vm._v(" RESERVES ")]),_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-reserved-items'
                    : 'archive-project-reserved-items',
                params: {
                    projectId: _vm.projectId,
                },
            }}},[_vm._v(" PRJ. STOCK ")])],1),_c('v-card',[_c('v-card-text',{staticClass:"overflow-y-auto",style:(`height: ${_vm.height}px;`)},[_c('router-view')],1)],1),_c('Errors')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }