<template>
    <div v-resize="onResize" class="fill-height mt-n3 mx-n4" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab
                :to="{
                    name: !this.nameRoute.includes('archive-project')
                        ? 'project-stock-items'
                        : 'archive-project-stock-items',
                    params: {
                        projectId,
                    },
                }"
            >
                ESM STOCK
            </v-tab>

            <v-tab
                :to="{
                    name: !this.nameRoute.includes('archive-project')
                        ? 'project-requested-items'
                        : 'archive-project-requested-items',
                    params: {
                        projectId,
                    },
                }"
            >
                RESERVES
            </v-tab>
            <v-tab
                :to="{
                    name: !this.nameRoute.includes('archive-project')
                        ? 'project-reserved-items'
                        : 'archive-project-reserved-items',
                    params: {
                        projectId,
                    },
                }"
            >
                PRJ. STOCK
            </v-tab>
        </v-tabs>
        <v-card>
            <v-card-text
                class="overflow-y-auto"
                :style="`height: ${height}px;`"
            >
                <router-view />
            </v-card-text>
        </v-card>
        <Errors />
    </div>
</template>

<script>
export default {
    name: 'ProjectReserves',
    props: {
        projectId: String,
    },
    components: {
        Errors: () => import('@/components/Layout/Errors.vue'),
    },
    data: () => ({
        height: 0,
        nameRoute: undefined,
    }),
    created() {
        this.nameRoute = this.$router.currentRoute.name
    },
    methods: {
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
            } = this.$refs
            this.height = containerHeight - tabsHeight + 28
        },
    },
}
</script>

<style></style>
